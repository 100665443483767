<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-select
          v-model="query.stationMacId"
          placeholder="请选择站点"
          filterable
          clearable
          @change="getList(1)"
        >
          <el-option
            v-for="(item, index) in stationList.list"
            :key="index"
            :label="item.stationName"
            :value="item.stationMacId"
          ></el-option>
        </el-select>
      </el-col>

      <!-- <el-col :span="4">
        <el-select
          v-model="query.status"
          placeholder="全部出入库状态"
          @change="getList(1)"
          clearable
        >
          <el-option label="入库" :value="1"></el-option>
          <el-option label="已出库" :value="2"></el-option>
        </el-select>
      </el-col> -->

      <el-col :span="4">
        <el-input v-model="query.macid" placeholder="电池编号"></el-input>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
        <el-button type="primary" @click="doExportStationBattery" v-auth="this.per.EXPORT_STATION_BATTERY"
          >导出站点电池</el-button
        >
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="macid" prop="batteryMacId"></el-table-column>
      <el-table-column label="站点名称" prop="stationMacId"></el-table-column>
      <el-table-column label="仓门" prop="name"></el-table-column>
    </el-table>
    <el-pagination
      layout="prev, pager, next, jumper"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
  
</template>

<script>
import { fetchStationBatteryList, exportStationBattery } from "@/api/station";
import { areas } from "@/common/area.js";
import { mapState } from "vuex";
import moment from 'moment';
// import StationBatteryRecord from '@/components/view-components/station-battery-record';

export default {
  //components:{StationBatteryRecord},
  computed: mapState({
    agentList(state) {
      return state.agent.agentList;
    },
    stationList(state) {
      return state.station.stationList || { map: {}, list: [] };
    },
  }),

  data() {
     let startTime = moment()
      .subtract(1, "y")
      .startOf("d")
      .format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().endOf("d").format("YYYY-MM-DD HH:mm:ss");

    return {
      
      dataSource: [],
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      exportQuery: {
        startTime: startTime,
        endTime: endTime,
        status: 1,
      },
      query: {
        stationMacId: ""
      },

      modalData: {},
      modalType: "add",
      coord: {
        latitude: "",
        longitude: "",
      },

      total: 0,
    };
  },
  methods: {
    doExportStationBattery() {
      exportStationBattery(this.exportQuery).then((res) => {
        this.util.apiDownLoadFile(
          res.data,
          "application/vnd.ms-excel",
          `站点电池.xlsx`
        );
      });
    },
    changeAgent() {
      this.query.stationId = -1;
      this.getList();
    },
    showEdit(data) {
      this.modalData = this.deepClone(data);
      this.modalType = "edit";
      this.modalData.areaArr = [
        this.modalData.province,
        this.modalData.city,
        this.modalData.area,
      ];
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    getList(current) {
      console.log(this.query,"this.data.query");
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
    //  this.$refs['stationBatteryRecord'].getList(current);
      fetchStationBatteryList({
        ...this.query,
      }).then((res) => {
        this.dataSource = res.data.data.stationCabinetList || [];
        this.total = res.data.data.total;
        this.dataSource.map((item) => {
          item.operationName = this.agentList.map[item.operationId];
        });
      });
    },
  },

  mounted() {
    this.$store.dispatch("fetchAgentListAll");
    this.$store.dispatch("fetchStationAll");
  },
};
</script>
